import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation, ChangeDetectorRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, NavigationEnd, Event } from "@angular/router";
import { Subscription } from "rxjs";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import PerfectScrollbar from "perfect-scrollbar";
import { TourService } from "ngx-tour-ng-bootstrap";
import { filter } from "rxjs/operators";
import { MenuItems } from "../core/menu/menu-items/menu-items";
import { PageTitleService } from "../core/page-title/page-title.service";
import { AuthService } from "../service/auth/auth.service";
import { CoreService } from "../service/core/core-service.service";
import { AfterContentChecked } from "@angular/core";
import { GlobalService } from "app/shared/global.service";
import { CommonService } from "app/shared/common.service";
import { CustomizeService } from "app/shared/customize.service";
import { DashboardV1Service } from "app/dashboard/dashboard-v1/dashboard-v1.service";
import { ToastrService } from "ngx-toastr";
declare var require;

const screenfull = require("screenfull");

@Component({
  selector: "epion-layout",
  templateUrl: "./main-material.html",
  styleUrls: ["./main-material.scss"],
  encapsulation: ViewEncapsulation.None
})

export class MainComponent implements OnInit, OnDestroy, AfterContentChecked {

  dark: boolean;
  boxed: boolean;
  collapseSidebar: boolean;
  compactSidebar: boolean;
  customizerIn: boolean = false;
  chatWindowOpen: boolean = false;
  chatSidebar: boolean = false;
  sidebarClosed: boolean = false;
  chatpanelOpen: boolean = false;
  sidenavOpen: boolean = true;
  isMobile: boolean = false;
  isFullscreen: boolean = false;
  _opened: boolean = true;
  _showBackdrop: boolean = false;
  _closeOnClickOutside: boolean = false;
  showSettings: boolean = false;

  _mode: string = "push";
  sidenavMode: string = "side";
  themeSkinColor: any = "light";
  headerSkinColor: any = "light";
  root: any = "ltr";
  layout: any = "ltr";
  header: string;
  url: string;
  public innerWidth: any;
  input: any;
  miniSidebar: boolean = true;
  private _router: Subscription;
  private _mediaSubscription: Subscription;


  currentLang = "en";
  @ViewChild("sidenav") sidenav;

  constructor(public tourService: TourService,
    private coreService: CoreService,
    private authService: AuthService,
    public menuItems: MenuItems,
    private pageTitleService: PageTitleService,
    public translate: TranslateService,
    private router: Router,
    private media: MediaObserver,
    private cdr: ChangeDetectorRef,
    public globals: GlobalService,
    private dashboardV1Service: DashboardV1Service,
    private commonService: CommonService,
    private toasterService: ToastrService,
    private customizeService: CustomizeService) {

    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : "en");

    // this.tourService.events$.subscribe();
    // this.tourService.initialize([
    // 	{
    // 		anchorId: "start.tour",
    // 		content: "Welcome to the Epion tour!",
    // 		placement: "below",
    // 		title: "Welcome to Epion",
    // 	},
    // 	{
    // 		anchorId: "tour-search",
    // 		content: "Enjoying Search box with sugestion and many more things",
    // 		placement: "below",
    // 		title: "Search Box",
    // 	},
    // 	{
    // 		anchorId: "tour-full-screen",
    // 		content: "By pressing this button you can switch to fullscreen mode.",
    // 		placement: "below",
    // 		title: "Full Screen",
    // 	},
    // 	{
    // 		anchorId: "tour-ui",
    // 		content: "Create your frontend with beautifully designed UI-Panel. Pre integrated page
    //      templates and widgets provides you the power to create any design.",
    // 		placement: "right",
    // 		title: "Awesome UI-Panel",
    // 	}]
    // );

    // if (window.innerWidth > 1280) {
    // 	this.tourService.start();
    // }
  }

  public HasClientDetails(): boolean {
    return this.globals != null && this.globals.ClientID > 0;
  }

  // Getting Client Details using AuthService
  GetClientDetails() {
    const tokenInfo = this.authService.getDecodedToken();
    this.globals.ClientID = parseInt(tokenInfo["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"]);
    this.globals.LocationID = parseInt(tokenInfo["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/locality"]);
    this.globals.LoginTemplate = tokenInfo["role"];
    this.globals.UserID = tokenInfo["nameid"];
    this.globals.UserName = tokenInfo["unique_name"].toLowerCase();
    this.globals.IsDocterzClient = tokenInfo["actort"] == "True" ? true : false;
    this.globals.DefaultStore = tokenInfo["DefaultStore"];
    this.globals.DefaultSubStore = tokenInfo["DefaultSubStore"];
    this.globals.LicenseFor = tokenInfo["LicenseFor"];
    this.globals.HospitalBelongsTo = tokenInfo["HospitalBelongsTo"];
    this.globals.IsClientSuperAdmin = tokenInfo["IsClientAdmin"] == "True" ? true : false;
    this.globals.MultiClientLocation = tokenInfo["MultiClientLocation"] == "True" ? true : false;
    this.globals.ThirdPartyConsultantUser = tokenInfo["ThirdPartyConsultantUser"] == "True" ? true : false;

    this.globals.SideBarColor = tokenInfo["SideBarColor"];
    this.globals.HeaderColor = tokenInfo["HeaderColor"];
    this.globals.Theme = tokenInfo["Theme"];

    this.themeSkinColor = this.globals.SideBarColor == "" ? this.themeSkinColor : this.globals.SideBarColor;
    this.headerSkinColor = this.globals.HeaderColor == "" ? this.headerSkinColor : this.globals.HeaderColor;

    const date = new Date(0);
    this.globals.ExpireTime = new Date(date.setUTCSeconds(tokenInfo["exp"]));

    this.GetAuthentcationsForUserByUserId();
    this.globals.HasClientInfo = true;
  }

  // Getting Atuhentications For User
  GetAuthentcationsForUserByUserId() {
    this.commonService.GetMenuVisbleForUserByUserID(this.globals.UserID)
      .subscribe((data) => {
        this.globals.MenuComponentAuthentication = data;

        this.GetConfigurationDetails();
      });
  }

  // Getting Configuration Details
  GetConfigurationDetails() {
    this.commonService.GetConfigurationDetails(this.globals.ClientID, this.globals.LocationID)
      .subscribe((data) => {

        if (data.length > 0) {
          this.globals.configurations = data;

          this.loadClientReceiptBooks();
        }

        this.menuItems.updateMenuItems(this.globals.UserID);
        this.loadMessages();
      });
  }

  // Setting Receipt Book Details
  loadClientReceiptBooks() {
    if (this.globals.configurations) {

    }
  }


  // This method is used to display cutomized messages.
  // This method gives call to getMessages function from customizeService
  loadMessages() {
    this.customizeService.getMessages().subscribe((data) => {
      this.globals.messages = data;

      this.SetComponentVisible();
    });
  }

  // Sets Component Visibility
  SetComponentVisible() {
    for (let i = 0; i < this.globals.MenuComponentAuthentication.length; i++) {
      const ComponentName = this.globals.MenuComponentAuthentication[i]["ComponentName"];
      this.globals.VisibleComponentMenu[ComponentName] = {
        "ComponentID": this.globals.MenuComponentAuthentication[i]["ComponentID"],
        "AuthenticationType": this.globals.MenuComponentAuthentication[i]["AuthenticationType"]
      };
    }

    // Setting Component Visible Not In Menu
    this.SetComponentVisibleNotInMenu();
  }

  // Setting Component Visible Not In Menu
  SetComponentVisibleNotInMenu() {

    /* OPD And IPD Both */
    // Registration
    if (this.globals.VisibleComponentMenu["registration"]) {
      this.globals.registrationVisible = true;
    }

    // Case Paper
    if (this.globals.VisibleComponentMenu["case-paper"]) {
      this.globals.casePaperVisible = true;
    }

    // Nursing Observation
    if (this.globals.VisibleComponentMenu["nursing-observation"]) {
      this.globals.nursingObservationVisible = true;
    }

    // IPD Refund
    if (this.globals.VisibleComponentMenu["ipd-refund"]) {
      this.globals.IpdrefundVisible = true;
    }

    // OPD Refund
    if (this.globals.VisibleComponentMenu["opd-refund"]) {
      this.globals.OpdrefundVisible = true;
    }

    // Cashless
    if (this.globals.VisibleComponentMenu["cashless"]) {
      this.globals.cashlessVisible = true;
      // this.globals.imageuploadVisible = true;
    }

    // Cashless Dummy Bill
    if (this.globals.VisibleComponentMenu["cashless-dummy-bill"]) {
      this.globals.cashlessDummyBillVisible = true;
    }

    // Pathology Result Entry
    if (this.globals.VisibleComponentMenu["pathology-result-entry"]) {
      this.globals.pathologyResultEntryVisible = true;
    }

    // file upload
    if (this.globals.VisibleComponentMenu["image-upload"]) {
      this.globals.imageuploadVisible = true;
    }

    // Doctor Request
    if (this.globals.VisibleComponentMenu["doctor-request"]) {
      this.globals.doctorRequestVisible = true;
    }

    // Pre Auth
    if (this.globals.VisibleComponentMenu["pre-auth"]) {
      this.globals.preAuthVisible = true;
    }

    // email
    if (this.globals.VisibleComponentMenu["email"]) {
      this.globals.emailVisible = true;
    }

    // queries
    if (this.globals.VisibleComponentMenu["queries"]) {
      this.globals.queriesVisible = true;
    }

    // Enhancement
    if (this.globals.VisibleComponentMenu["enhancement"]) {
      this.globals.EnhancementVisible = true;
    }
    // Final Approval
    if (this.globals.VisibleComponentMenu["final-approval"]) {
      this.globals.FinalApprovalVisible = true;
    }
    // Settlement Fightback
    if (this.globals.VisibleComponentMenu["settlement-fightback"]) {
      this.globals.SettlementFightbackVisible = true;
    }

    /* OPD */

    // Bill
    if (this.globals.VisibleComponentMenu["bill"]) {
      this.globals.billVisible = true;
    }

    // OPD Advance
    if (this.globals.VisibleComponentMenu["opd-advance"]) {
      this.globals.OpdadvanceVisible = true;
    }

    // Session data
    if (this.globals.VisibleComponentMenu["session-data"]) {
      this.globals.SessionDatavisible = true;
    }

    // Ipd Bill Reconciliation
    if (this.globals.VisibleComponentMenu["opd-bill-reconciliation"]) {
      this.globals.opdBillReconciliationVisible = true;
    }

    /* IPD */

    // Bill
    if (this.globals.VisibleComponentMenu["ipd-bill"]) {
      this.globals.ipdBillVisible = true;
    }

    // IPD Advance
    if (this.globals.VisibleComponentMenu["ipd-advance"]) {
      this.globals.IpdadvanceVisible = true;
    }

    // Payment
    if (this.globals.VisibleComponentMenu["ipd-bill-payment"]) {
      this.globals.ipdBillPaymentVisible = true;
    }

    // Discharge
    if (this.globals.VisibleComponentMenu["discharge"]) {
      this.globals.dischargeVisible = true;
    }

    // Bed Transfer
    if (this.globals.VisibleComponentMenu["bed-transfer"]) {
      this.globals.bedTransferVisible = true;
    }

    // IPD Short Bill
    if (this.globals.VisibleComponentMenu["ipd-short-bill"]) {
      this.globals.ipdShortBillVisible = true;
    }

    // pathology Result Entry
    if (this.globals.VisibleComponentMenu["pathology-result-entry"]) {
      this.globals.pathologyResultEntryVisible = true;
    }

    // radiology Result Entry
    if (this.globals.VisibleComponentMenu["radiology-result-entry"]) {
      this.globals.radiologyResultEntryVisible = true;
    }

    // Ipd Bill Reconciliation
    if (this.globals.VisibleComponentMenu["ipd-bill-reconciliation"]) {
      this.globals.ipdBillReconciliationVisible = true;
    }

    // Sales Return
    if (this.globals.VisibleComponentMenu["sales-return"]) {
      this.globals.salesRetunVisible = true;
    }

    // Goods Receipt Note Return
    if (this.globals.VisibleComponentMenu["goods-receipt-note-return"]) {
      this.globals.goodsReceiptNoteRetunVisible = true;
    }

    // Return From Sub Store Return
    if (this.globals.VisibleComponentMenu["return-from-sub-store"]) {
      this.globals.returnFromSubStoreReturnVisible = true;
    }

    // External Pathology Billing
    if (this.globals.VisibleComponentMenu["external-pathology-billing"]) {
      this.globals.externalPathologyBillingVisible = true;
    }

    // Lupin Pathology Report
    if (this.globals.VisibleComponentMenu["pathology-report"]) {
      this.globals.pathologyReportVisible = true;
    }

    // OPD Report
    if (this.globals.VisibleComponentMenu["opd-reports"]) {
      this.globals.opdReportVisible = true;
    }
    // IPD Report
    if (this.globals.VisibleComponentMenu["ipd-reports"]) {
      this.globals.ipdReportVisible = true;
    }
    // Common Report
    if (this.globals.VisibleComponentMenu["common-reports"]) {
      this.globals.commonReportVisible = true;
    }
    // Inventory Report
    if (this.globals.VisibleComponentMenu["inventory-reports"]) {
      this.globals.inventoryReportVisible = true;
    }
    // patient prescription
    if (this.globals.VisibleComponentMenu["patient-prescription"]) {
      this.globals.patientPrescriptonVisible = true;
    }

  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.pageTitleService.title.subscribe((val: string) => {
      this.header = val;
    });

    if (+this.globals.ClientID === 0) {
      this.GetClientDetails();
    }

    this._router = this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        this.url = event.url;

        if (document.querySelector("#sideNavMenu > aside").classList.contains("ng-sidebar--opened")) {
          document.getElementById("toggleButton").click();
        }
      });

    if (this.url != "/session/login" && this.url != "/session/register"
      && this.url != "/session/forgot-password" && this.url != "/session/lockscreen") {
      const elemSidebar = <HTMLElement>document.querySelector(".sidebar-container");

      /** Perfect scrollbar for sidebar menu **/
      if (window.matchMedia(`(min-width: 1280px)`).matches) {
        const ps = new PerfectScrollbar(elemSidebar, {
          wheelSpeed: 2,
          wheelPropagation: true,
          minScrollbarLength: 20
        });
        ps.update();
      }

      /** Perfect scrollbar for chat window **/
      const elemChatbar = <HTMLElement>document.querySelector(".chat-inner ");
      if (window.matchMedia(`(min-width: 1280px)`).matches) {
      }

      this._toggleOpened();
    }

    if (this.media.isActive("xs") || this.media.isActive("sm") || this.media.isActive("md")) {
      this._mode = "over";
      this._closeOnClickOutside = true;
      this._showBackdrop = true;
      this._opened = false;
      this.sidebarClosed = false;
    }

    this._mediaSubscription = this.media.media$.subscribe((change: MediaChange) => {
      const isMobile = (change.mqAlias == "xs") || (change.mqAlias == "sm") || (change.mqAlias == "md");

      this.isMobile = isMobile;
      this._mode = (isMobile) ? "over" : "push";
      this._closeOnClickOutside = (isMobile) ? true : false;
      this._showBackdrop = (isMobile) ? true : false;
      this._opened = !isMobile;
      this.sidebarClosed = false;
    });


    // Add class on focus of search box in header
    // document.getElementById("search-field").addEventListener("focusin", function () {
    //   document.getElementById("search-field").parentElement.classList.add("search-active");
    // });

    // document.getElementById("search-field").addEventListener("focusout", function () {
    //   document.getElementById("search-field").parentElement.classList.remove("search-active");
    // });
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this._router.unsubscribe();
    this._mediaSubscription.unsubscribe();
  }

  /**
    * toggleFullscreen method is used to show a template in fullscreen.
    */
  toggleFullscreen() {
    if (screenfull.enabled) {
      screenfull.toggle();
      this.isFullscreen = !this.isFullscreen;
    }
  }

  /**
    * _toggleOpened method is used to toggle a menu list.
    */
  public _toggleOpened() {
    this._opened = !this._opened;
    this.sidebarClosed = !this.sidebarClosed;
  }

  /**
    * customizerFunction is used to open and close the customizer.
    */
  customizerFunction() {
    this.customizerIn = !this.customizerIn;
  }

  /**
    * chatWindowFunction is used to open and close the chat window.
    */
  chatWindowFunction() {
    this.chatWindowOpen = !this.chatWindowOpen;
  }

  /**
    * chatSidebarFunction is used to open and close the chat sidebar list.
    */
  chatSidebarFunction() {
    this.chatSidebar = !this.chatSidebar;
  }

  /**
    * changeThemeColor function filter the color for sidebar section.
    */
  changeThemeColor(color) {
    this.themeSkinColor = color;
  }

  /**
    * changeHeaderColor function filter the color for header section.
    */
  changeHeaderColor(color) {
    this.headerSkinColor = color;
  }

  savetheme() {
    console.log(this.themeSkinColor);
    console.log(this.headerSkinColor);
    console.log(this.globals.UserID);

    this.commonService.savetheme(this.themeSkinColor, this.headerSkinColor, this.globals.UserID).subscribe((data) => {
      if (data == "OK") {
        this.toasterService.success("Record save successfully.");
      } else {
        this.toasterService.error("Something went wrong.");
      }

    });

  }

  /**
    * As router outlet will emit an activate event any time a new component is being instantiated.
    */
  onActivate(scrollContainer) {
    scrollContainer.scrollTop = 0;
  }

  /**
    * changeRTL method is used to change the layout of template.
    */
  changeRTL(isChecked) {
    if (isChecked) {
      this.layout = "rtl";
      this.coreService.rtlShowStatus = true;
    } else {
      this.layout = "ltr";
      this.coreService.rtlShowStatus = false;
    }
  }

  /**
    * toggleSidebar method is used to switch between Icon sidebar and Sidebar.
    */
  toggleSidebar(isChecked) {
    console.log(isChecked);
    this.miniSidebar = !isChecked;
    if (isChecked) {
      document.getElementById("showSidebar").classList.remove("icon-sidebar");
      document.getElementsByClassName("app")[0].classList.remove("icon-sidebar-wrap");
      document.getElementById("boxed-layout").classList.remove("disabled-checkbox");
    } else {
      document.getElementById("showSidebar").className += " icon-sidebar";
      document.getElementsByClassName("app")[0].className += " icon-sidebar-wrap";
      document.getElementById("boxed-layout").className += " disabled-checkbox";
    }
  }

  /**
    * logOut method is used to log out the  template.
    */
  logOut() {
    this.authService.logOut();
  }

  /**
    *closeOnClickOutside method is used when click on outside close/open the sidebar.
    */
  closeOnClickOutside() {
    if (this._opened) {
      this.sidebarClosed = false;
    } else {
      this.sidebarClosed = true;
    }
    return this._closeOnClickOutside;
  }

  /**
    * boxedLayout method is used to show the box layout.
    */
  boxedLayout(isChecked) {
    if (isChecked) {
      this.boxed = true;
      document.getElementById("icon-sidebar").className += " disabled-checkbox";
    } else {
      this.boxed = false;
      document.getElementById("icon-sidebar").classList.remove("disabled-checkbox");
    }
  }
}
