export const environment = {
  production: true,
  // apiUrl: 'http://192.168.100.11/carme/api/', // Prime Care local
  // apiUrl: 'http://1.186.242.26:81/carme/api/', // Prime Care global
 //  apiUrl: 'http://192.168.100.11/carme/api/', // Mac Care
  // apiUrl: 'https://epionhealth.in/carme/api/', // Live Server For India
  // apiUrl: 'https://om.epionhealth.in/carme/api/' // Latest Live Server For India
 //  apiUrl: 'https://demo.epionhealth.in/carme/api/' // Latest Live Server For Oman
 //  apiUrl: 'http://test.epionhealth.in/carme/api/' // Latest Testing Server
  // apiUrl: 'https://path.epionhealth.in/carme/api/' // Pathology Server
  // apiUrl: 'https://pharma.epionhealth.in/carme/api/' // Pharmacy Server
  // apiUrl: 'https://gw.epionhealth.in/carme/api/', // Grand world Elder
  // apiUrl: 'https://claim.epionhealth.in/carme/api/', // Cashless AI
  // apiUrl: 'https://salus.epionhealth.in/carme/api/', // Cashless AI New
   //  apiUrl: 'http://app1:82/carme/api/', // MacCare salus local
  // apiUrl: 'http://salus:81/carme/api/', // MacCare salus local
   apiUrl: 'http://eclaims.in/carme/api/', // eclaims
  // ng build --aot --watch --configuration --outputHashing=all
};
