<!--Login Wrapper-->
<div class="login-wrapper pt-xl-5">
  <div class="login-content text-center">
    <div class="login-inner-wrapper-2 shadow-box p-4">
      <div class="sidebar-logo d-flex justify-content-center mb-4">
        <div class="logo-sign">
          <img src="assets/img/epion-logo.png" alt="Epion Login" height="50" />
        </div>
      </div>
      <div class="mb-4">
        <p class="text-dark font-weight-bold mb-0 letter-space-1">
          {{'Welcome To Epion'|translate}}
        </p>
        <h2 class="text-primary font-weight-normal">
          {{'Sign into your account'|translate}}
        </h2>
      </div>
      <form [formGroup]="loginForm" #form="ngForm" (ngSubmit)="!isInProgress && logIn()" autocomplete="off"
        class="login-form">
        <div class="form-group"
          [ngClass]="{ 'has-error': formControls.UserName?.touched && formControls.UserName.errors }">
          <div class="input-group mb-3">
            <input name="username" formControlName="UserName" class="form-control session-input-font input-square"
              type="text" placeholder="User Name" autocomplete="username" />
            <div class="input-group-append">
              <span class="input-group-text">
                <i class="fa fa-user"></i>
              </span>
            </div>
          </div>
          <span class="text-danger" *ngIf="formControls.UserName?.touched && formControls.UserName.errors?.required">
            Use name is required!
          </span>
          <span class="text-danger" *ngIf="formControls.UserName.errors?.minlength">
            Use name length should be minimum 4 characters!
          </span>
        </div>
        <div class="form-group"
          [ngClass]="{ 'has-error': formControls.Password?.touched && formControls.Password.errors }">
          <div class="input-group mb-3">
            <input name="password" formControlName="Password" placeholder="Password"
              class="form-control session-input-font input-square" [type]="!isActive ? 'password' : 'text'"
              autocomplete="current-password" />
            <div class="input-group-append">
              <span class="input-group-text">
                <i [ngClass]="!isActive ? 'fa fa-eye':'fa fa-eye-slash'" (click)="isActive = !isActive"></i>
              </span>
            </div>
          </div>
          <span class="text-danger" *ngIf="formControls.Password?.touched && formControls.Password.errors?.required">
            Password is required!
          </span>
          <span class="text-danger" *ngIf="formControls.Password.errors?.minlength">
            Password length should be minimum 4 characters!
          </span>
        </div>
        <div class="login-meta mb-3">
          <div class="row">
            <!-- <div class="col-12 col-sm-12 col-md-12 text-left text-sm-right">
              <a routerLink="/session/forgot-password" class="text-dark">{{'Forgot Password?'|translate}}</a>
            </div> -->
          </div>
        </div>
        <div class="mb-3">
          <button type="submit" [disabled]="!form.valid || isInProgress"
            class="btn btn-primary btn-block btn-lg btn-square">
            {{'Sign In'|translate}}
          </button>
        </div>
        <!-- <div class="mb-3">
          <a routerLink="/session/register" class="text-dark">
            {{'Still no account? Please go to Register'|translate}}
          </a>
        </div>
        <div class="login-social-icon">
          <ul class="list-inline">
            <li class="list-inline-item"><a href="javascript:void(0)"><i
                  class="fa fa-google-plus fa-lg text-warning"></i></a>
            </li>
            <li class="list-inline-item"><a href="javascript:void(0)"><i class="fa fa-twitter fa-lg text-info"></i></a>
            </li>
            <li class="list-inline-item"><a href="javascript:void(0)"><i
                  class="fa fa-facebook-official fa-lg text-primary"></i></a></li>
            <li class="list-inline-item"><a href="javascript:void(0)"><i
                  class="fa fa-instagram fa-lg text-danger"></i></a>
            </li>
          </ul>
        </div> -->
      </form>
    </div>
    <!-- login form wrapper closed -->
  </div>
  <!-- login-one- wrapper closed -->
</div>